.upload .uploadvideo {
  display: flex;
}
.upload .uploadvideo .el-upload {
  height: auto !important;
  border: none;
}
.upload .el-button {
  height: auto !important;
}
.spanbutton {
  color: #606266;
}
.spanbutton:hover {
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
